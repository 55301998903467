.billing-payment-ctn{
    display: flex;
    margin: 5px 0;
    gap: 5px;
    flex: 1;
}
.billing-payment-ctn-left{
    flex: 1;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* max-height: 83vh; */
}
.payment-table-div{
  height: 90%;
  overflow-y: scroll;
}
.payment-table-div::-webkit-scrollbar {
  width: 6px;
}

.payment-table-div::-webkit-scrollbar-thumb {
  background-color: #e3e1e1;
  border-radius: 6px;
}

.payment-table-div::-webkit-scrollbar-track {
  background-color: #f0f0f0;
}
.billing-payment-ctn-right{
    width: 35%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #fff;
    padding-top: 8px;
}
.billing-payment-ctn-left table{
    border-collapse: collapse;
    width: 100%;
    max-height: 50vh;
    overflow-y: auto scroll;
}
.billing-payment-ctn-left .bottom{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f57200;
    padding: 5px 25px;
    font-weight: 600;
}
.billing-payment-ctn-left .bottom p{
    margin: 0;
    color:#fff;
}
.billing-payment-ctn-left .bottom div{
    height: 40px;
    background-color: #fff;
    padding: 10px 20px;
    padding-left: 30px;
    border-radius: 6px;
    font-weight: 500;
}

.payment-table-div table thead {
    background-color: #f0f0f0; 
    position: sticky;
    top: 0px;
  }

  .payment-table-div table th {
    padding: 10px;
    text-align: start;
    background-color: #37474f;
    color: #fff;
  }
  .payment-table-div table th.name {
    background-color: #f57200;
    border-right: 2px solid white;
  }
  
  .payment-table-div table td {
    padding: 10px;
    border: none;
    text-align: start;
    font-size: 0.9rem;
    font-weight: 500;
  }

  .billing-payment-input-rows{
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 0 10px;
    margin-bottom: 10px;
  }

  .payment-types-ctn{
    display: flex;
    flex-direction: column;
    height: calc(100% - 63px);
  }
  .payment-types-ctn-head{
    width: 100%;
    padding: 10px;
    background-color: #37474f;
    color: #fff;
  }
  .payment-types-row-ctn{
    display: flex;
    flex-direction: column;
    padding: 10px 15px;
    gap: 5px;
    background-color: #fff;
  }
  .payment-types-row{
    display: flex;
    padding: 10px 15px;
    align-items: center;
    background-color: #e6e8e9;
    color:#37474f;
    flex: 1;
    height: 50px;
    cursor: pointer;
    justify-content: space-between;
  }
  .payment-types-row-list{
    display: flex;
    align-items: center;
    gap: 3px;
  }
  .payment-types-row p{
    color:#37474f;
    margin: 0;
  }
  .payment-types-row input{
    color: #000;
    border: none;
    width: 80px;
    padding-right: 5px;
    text-align: right;
    background-color: transparent;
    height: 35px;
    margin-left: auto;
  }
  .payment-types-row input.editable{
    border: 1px solid rgb(9, 80, 9) ;
  }
  .payment-types-row input.card-aproval-code{
    text-align: start;
    border: 1px solid #585252;
    margin: 0;
    font-size: 0.7rem;
  }
  .payment-types-row-list button{
    /* height: 40px; */
    background-color: #000;
    cursor: pointer;
    border: none;
    width: 40px;
    height: 50px;
  }
  .payment-types-row-list button:hover{
    background-color: #682c2c;
  }

  .payment-types-row-auto-complete{
    padding: 0px 15px;
  }
  .payment-types-row-auto-complete .MuiAutocomplete-root{
    width: 100%;
    height: 100%;
  }
  .payment-types-row-auto-complete .MuiInputBase-root{
    padding: 0!important;
  }
  .payment-types-row-auto-complete input{
    padding: 10px 0!important;
    text-align: start !important;
  }
  .payment-types-row-auto-complete .MuiOutlinedInput-notchedOutline{
    border: none;
    padding: 0;
    height: 100%;
  }
.billing-payment-ctn-left h4{
  margin: 8px 15px;
  margin-top: 13px;
}
  .payment-view-order-table{
    height: 98%;
    overflow-y: scroll;
    padding: 0!important;
  }
  .payment-view-order-table thead{
    background-color: #eeeeee !important;
  }

  .payment-view-order-table span.amount-due{
    color: #fff;
    background-color: #1135ff;
    padding: 4px 6px;
    border-radius: 10px;
}
  .billing-payment .flex-column{
    background-color: #fff;
    justify-content: space-between;
  }
  .payment-button-ctn{
    width: 100%;
    padding: 10px 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 1%;
  }
  .payment-button-ctn .billing-payment-button,.payment-button-ctn .billing-Hold-button{
    width: 49%;
  }
  .payment-button-ctn .billing-placeOrder-button{
    width: 100%;
    margin-top: 5px;
  }

  /* dialog wallet and change */
  .wallet-change-dialog{
    display: flex;
    flex-direction: column;
    padding: 18px 25px;
    gap: 20px;
    min-width: 500px;
    width: 100%;
    max-width: 750px;
  }

  .wallet-change-dialog .MuiOutlinedInput-root{
    padding: 0!important;
  }
  .wallet-change-dialog .head{
   justify-content: start;
   gap: 20px;
   font-weight: 600;
  }
  .wallet-change-dialog .head .icon{
    background-color: #ade8c2;
    border-radius: 50%;
    padding: 8px;
    padding-bottom: 10px;
  }
  .wallet-change-dialog .head img{
    width: 25px;
    height: 25px;
  }
  .wallet-change-dialog .input-ctn{
    width: 100%;
    border: 1px solid #ebeefe;
    display: flex;
    position: relative;
  }
  .wallet-change-dialog .input-ctn button.btn{
    background-color: #dde1f3;
    color: #000;
    min-width: 100px;
    margin: 0;
    border-radius: 0;
  }
  .customer-popup-input-ctn{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 15px 1%;
  }
  .customer-popup-input-ctn .input-ctn{
    width: 49%;
  }
  .customer-popup-input-ctn .input-ctn .doc-validation-alert{
    bottom: -15px!important;
    margin: 0;
  }
  .wallet-change-dialog .input-ctn button:hover{
    background-color: #7c89c3;
    color: #504f4f;
  }
  .wallet-change-dialog .input-ctn input{
   border: none;
   flex: 1;
   text-align: end;
   padding-right: 15px;
  }
  .wallet-change-btn{
    display: flex;
    justify-content: flex-end;
    position: relative;
  }
  .wallet-change-btn .doc-validation-alert{
    color:green;
    cursor:pointer;
    top:-16px;
    margin: 0;
  }
  .customer-popup-autocomplete{
    width: 100%;
    height: 40px;
  }
  .customer-popup-autocomplete input{
   text-align: start!important;
  }
  .customer-popup-autocomplete .MuiOutlinedInput-root{
    padding: 0 9px;
  }
  .customer-popup-autocomplete fieldset{
    border: none;
  }
  .wallet-change-btn .btn-secondary-outlined{
    width: 70px;
    height: 30px;
    font-size: 0.85rem;
  }
  .wallet-change-btn .btn-primary{
    height: 30px;
    width: 120px;
    font-size: 0.85rem;
    background-color: #16a90a;
  }

  
  .reservation-top-right{
    gap: 12px;
  }
  .reservation-top-right button.btn{
    width: auto;
    white-space: nowrap;
    margin: 10px 0;
    padding: 0 12px;
    height: 30px;
  }

  .billing-payment-ctn .action-buttons-ctn{
    position: absolute;
    bottom: 35px;
    right: 15px;
    justify-content: flex-end;
    gap: 15px;
  }
  .billing-payment-ctn .action-buttons-ctn button{
    border-radius: 40px;
    padding: 15px;
    text-align: center;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    white-space: nowrap;
    height: 50px;
    font-size: 0.9rem;
    font-weight: 500;
  }
  .billing-payment-ctn .action-buttons-ctn button .edit-icon1{
    font-size: 1.2rem;
    color: #0019f6;
  }
  .billing-payment-ctn .action-buttons-ctn button.edit{
    border: 1px solid #0019f6;
    color: #0019f6;
    background-color: #ced3ff;
  }
  .billing-payment-ctn .action-buttons-ctn button.add{
    border: 1px solid #00cf4e;
    color: #00cf45;
    background-color: #dafae5;
  }
  .billing-payment-ctn .action-buttons-ctn button.cancel{
    border: 1px solid #f60000;
    color: #ff0000;
    background-color: #fff0f0;
  }
  .billing-payment-ctn .action-buttons-ctn button.edit:hover{
    border: 2px solid #d0d4f4;
    background-color: rgb(237, 238, 247);
  }
  .billing-payment-ctn .action-buttons-ctn button.add:hover{
    border: 2px solid #daf0e2;
    background-color: rgb(237, 238, 247);

  }
  .billing-payment-ctn .action-buttons-ctn button.cancel:hover{
    border: 2px solid #f0cece;
    background-color: rgb(237, 238, 247);
  }

  .add-reservation-ctn{
    display: flex;
    flex-direction: column;
    padding: 10px 25px;
    width: 75vw;
  }
  .add-reservation-ctn .header-top{
    width: 100%;
    justify-content: start;
    gap: 10px;
  }
  .add-reservation-ctn .header-top button{
    width: 100px;
  }

  .add-reservation-ctn h3.res-name{
    margin: 0;
  }
  .add-reservation-ctn p.add-tables{
    margin: 0;
    font-size: 1.2rem;
  }

  .add-reservaion-input-ctn{
    gap: 10px 0;
    padding: 20px 0;
    justify-content: space-between;
  }
  .add-reservaion-input-ctn .new-global-single-input .MuiInputBase-root{
    padding: 0;
    padding-right: 0!important;
  }
  .add-reservaion-input-ctn .new-global-single-input button.add-customer-button{
    width: 40px;
    min-width: 30px;
    border-right: 1px solid #b1b1b1;
    color:#000
  }

  .add-reservaion-input-ctn .table-box{
    width: calc(10% - 12px);
    min-height: 60px;
    background-color: #e7e7e7;
    border-radius: 6px;
    cursor: pointer;
    font-weight: 600;
    font-size: 1rem;
  }
  .add-reservaion-input-ctn .table-box-selected{
    color: #0008ff;
    border: 1px solid #1a21ff;
    background-color: #e0e0ff;
  }
  .add-reservaion-input-ctn .table-box:hover{
    color: #0008ff;
    border: 1px solid #1a21ff;
  }
  .billing-payment-ctn .toggle-checkbox:checked{
    background-color: #5c5ce5;
    border: 1px solid #5c5ce5;
    box-shadow: inset 20px 0 0 0 #ddddff;
    
  }


  .multi-order-update-ctn{
    display: flex;
    flex-direction: column;
    background-color: #e7e7e7;
    min-width: 200px;
    padding: 10px 15px;
    border-radius: 6px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
    margin-top: 10px;
    margin-right: 10px;
  }

  .multi-order-update-ctn .order-ctn{
   padding: 5px 0;
    border-bottom: 1px dashed #646464;
  }
  .multi-order-update-ctn .order-ctn p{
    white-space: nowrap;
    margin: 0;
    font-weight: bolder;
    cursor: default;
  }
  .multi-order-update-ctn .order-ctn button{
    width: 40px;
    background-color: #c5c5c594;
  }
  .multi-order-update-ctn .order-ctn button:hover{

    background-color: #ffffff94!important;
  }
  .multi-order-update-ctn .order-ctn .btn i{
    font-size: 16px;
  }
  @media(max-width:1200px){
    .payment-types-row p{
      font-size: 0.7rem;
    }
    .payment-types-row-list button{
      width: 30px;
      font-size: 1rem;
    }
    .payment-types-row-list button svg{
      font-size: 1rem;
    }
  }