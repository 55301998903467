.kot-card-wrapper {
  padding: 1em;
  border-radius: 5px;
  background-color: var(--background);
  color: var(--text-color);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  transform: scale(1);
  transition: transform 0.4s ease-in-out;
  border: 5px solid transparent;
  width: 26em;
  height: 27em;
}

.kot-card-wrapper:hover {
  transform: scale(1.02);
  border: 5px solid var(--focus);
}

.kot-card-container {
  /* min-height:28em ; */
}
.kot-card-header {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.5em;
}
.kot-card-header p {
  margin: 0;
  font-size: 12px;
  width: 15em;
}
.kot-card-header p span {
  font-weight: 600;
}

.kot-card-header :nth-child(even) {
  text-align: end;
}
.card-order-no,
.card-table-no,
.card-token-no,
.card-date {
  font-weight: 600;
}
.card-token-no {
  color: red;
}
.card-print-container {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}
.card-print-container span {
  font-weight: 600;
}
.card-order-list-container {
  margin-block-end: 1em;
  height: 12em;
  overflow-y: scroll;
}
.card-order-list-container ul {
  width: 24.5em;
}
.card-print-container {
  display: flex;
  justify-content: space-between;
  background-color: var(--print-container);
  padding: 0.5em;
  align-items: center;
}

.card-print-container span {
  font-size: 12px;
}

.card-print-btn {
  background-color: #ef5505;
  border: none;
  padding: 0.5em;
  color: #ffff;
  justify-content: center;
  display: flex;
  width: 4em;
  cursor: pointer;
}

.card-order-list-container ul {
  list-style: none;
  padding: 0;
  width: 23.4em;
}
.card-order-list-container ul li {
  padding: 0.5em;
  border-bottom: 1px dotted #e1e1e1;
}
.card-order-list-container ul li .card-order-list {
  display: flex;
  justify-content: space-between;
  position: relative;
}
.card-order-list-container ul li .card-order-list .cross-line{
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #000000;
  top: 10px;
}
.card-order-item-name {
  display: flex;
  align-items: center;
  gap: 0.8em;
}
.card-order-item-name span,
.card-order-quantity span {
  font-weight: 600;
}
.card-order-quantity {
  min-width: 3em;
  text-align: end;
}
.card-notes {
  border: 1px solid var(--border);
  padding: 0.5em;
}
.kot-compo-offer-container li {
  border-bottom: none !important;
}
.card-notes p {
  margin: 0;
  padding: 0;
  font-size: 13px;
}
.card-btn-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1em;
}

.card-btn-container button {
  padding: 0.5em;
  border: none;
  width: 13em;
  height: 2.5em;
  cursor: pointer;
}
.card-btn-container .order-completed span,
.card-btn-container .order-to-cook span,
.card-btn-container .order-delivery span,
.card-btn-container .order-cancel span {
  color: #5e0147;
  font-size: 13px;
  font-weight: 600;
}
.card-btn-container .order-completed,
.card-btn-container .order-to-cook,
.card-btn-container .order-delivery,
.card-btn-container .order-cancel {
  background-color: #ccbbc8;
}
.card-btn-container .order-reset {
  background-color: #124e88;
  color: #ffff;
}
.card-btn-container .order-pickup-ready {
  background-color: #9be4fc;
  color: #124e88;
  font-weight: 600;
}

.card-btn-container .order-reject {
  color: #ffff;
  background-color: #e10909;
}
.card-btn-container .order-reset span,
.card-btn-container .order-reset span {
  color: #ffff;
  font-size: 13px;
  font-weight: 600;
}

/* scroll bar  */
/* Hide the scrollbar by default */
.card-order-list-container {
  overflow: hidden;
}

/* Show the scrollbar when the container is hovered */
.card-order-list-container:hover {
  overflow-y: scroll; /* Show vertical scrollbar */
}

/* Customize scrollbar appearance */
.card-order-list-container::-webkit-scrollbar {
  width: 5px; /* width of the scrollbar */
}

.card-order-list-container::-webkit-scrollbar-thumb {
  background-color: #000; /* color of the thumb */
}

/* Ensure scrollbar is not shown when not hovered */
.card-order-list-container * {
  scrollbar-width: none;
}

/* Show scrollbar thumb when hovered */
.card-order-list-container:hover *::-webkit-scrollbar-thumb {
  background-color: #000; /* color of the thumb */
}
