.popup-container {
  min-width: 30em;
  padding: 1.5em;

  display: flex;
  flex-direction: column;
  gap: 1em;
}
.popup-container .dialog-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-block-end: 0.5em;
}
.popup-input-container {
  height: 5em;
}
.popup-input-container .input-1 {
  position: relative;
  width: 100%;
}
.popup-input-container .display-color-div {
  /* transform: translateY(-50%); */
  position: absolute;
  content: "";
  height: 1em;
  width: 1em;
  top: 38%;
  right: 10%;
}
.background-selection-container {
  margin-top: 0.5em;
  display: flex;
  align-items: center;
  gap: 1em;
  height: 3em;
}
.background-selection-container .input-color-wrapper {
  position: relative;
  overflow: hidden;
  width: 30px;
  height: 30px;
  border: solid 2px #ddd;
  border-radius: 5px;
}
.background-selection-container .color-picker-input {
  position: absolute;
  right: -8px;
  top: -8px;
  width: 56px;
  height: 56px;
  border: none;
}
.popup-submit-btn-container {
  padding: 1.5em;
}
.popup-submit-btn {
  border: 2px solid #ff2b2b;
  padding: 0.5em;
  color: #ff2b2b;
  background-color: transparent;
  width: 10em;
  cursor: pointer;
}
.validation-error {
  color: #ff2b2b;
  font-size: 0.75rem;
}
.popup-container .css-1wc848c-MuiFormHelperText-root {
  margin-left: 0;
}
