.self-cart-container{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: start;
    width: 100%;
    align-items: center;
    height: 100%;
    padding-inline: 12px;
}
.self-cart-container img.empty-cart-svg{
    width: 100%;
    max-width: fit-content;
}

.self-cart-list-top{
    padding: 10px 0;
    margin-left: auto;
    width: 100%;
}
.self-cart-list-middle{
    width: 100%;
    flex: 1;
    overflow-y: auto;
}
.self-cart-item {
    display: flex;
    align-items: center;
    padding: 2px 10px;
    background-color: #f7f7f7;
    border-radius: 8px;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  
  .self-cart-item__image {
    width: 50px;
    height: 50px;
    border-radius: 4px;
    margin-right: 10px;
  }
  
  .self-cart-item__details {
    max-width: 120px;
    display: flex;
    flex-direction: column;
    padding: 6px 0;
    margin-right: auto;
    margin-left: 10%;
  }
  
  .self-cart-item__name {
    font-weight: bold;
    font-size: 14px;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .self-cart-item__size {
    color: #979797;
    margin: 2px 0;
    text-align: start;
  }
  
  .self-cart-item__quantity {
    display: flex;
    align-items: center;
    margin-right: 20px;
  }
  
  .self-cart-item__button {
    width: 24px;
    height: 24px;
    font-size: 18px;
    font-weight: bold;
    color: black;
    background: none;
    border: 1px solid #000;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  
  .self-cart-item__quantity-number {
    margin: 0 8px;
    font-size: 16px;
  }
  
  .self-cart-item__price {
    font-weight: bold;
    font-size: 16px;
    color: #000;
    margin-right: 20px;
  }
  
  .self-cart-item__delete {
    background-color: #f8d7da;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    color: red;
    font-size: 16px;
    padding: 15px 7px;
    display: flex;
    align-items: center;
  }

  .self-cart-end-ctn{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    max-width: 240px;
  }



  /* MobileCartItem.css */

.self-cart-card {
    display: flex;
    flex-direction: column;
    padding: 16px;
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    margin-bottom: 16px;
    transition: transform 0.1s ease;
  }
  
  .self-cart-card:hover {
    transform: translateY(-2px);
  }
  
  .self-cart-card__content {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
  }
  
  .self-cart-card__image {
    width: 70px;
    height: 70px;
    border-radius: 10px;
    object-fit: cover;
    margin-right: 12px;
  }
  
  .self-cart-card__details {
    flex: 1;
    text-align: start;
  }
  
  .self-cart-card__name {
    font-weight: 600;
    font-size: 18px!important;
    color: #333;
    margin: 0;
  }
  
  .self-cart-card__size {
    font-size: 13px!important;
    color: #666;
    margin-top: 4px;
  }
  
  .self-cart-card__actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
  }
  
  .self-cart-card__quantity {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  .self-cart-card__button {
    width: 32px;
    height: 32px;
    font-size: 18px;
    font-weight: bold;
    color: #555;
    background-color: #f0f0f0;
    border: none;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  .self-cart-card__button:hover {
    background-color: #e0e0e0;
  }
  
  .self-cart-card__quantity-number {
    font-size: 16px;
    font-weight: 600;
    color: #333;
  }
  
  .self-cart-card__price {
    font-size: 18px;
    font-weight: bold;
    color: #333;
  }
  
  .self-cart-card__delete {
    background: none;
    border: none;
    color: #e74c3c;
    font-size: 22px;
    cursor: pointer;
    transition: transform 0.1s ease;
  }
  
  .self-cart-card__delete:hover {
    transform: scale(1.1);
  }
  
.self-sub-total{
    padding: 5px 7px;
    border: 1px solid #e11503;
    color: #e11503;
    border-radius: 15px;
}
@media (min-width: 600px) {
  .self-cart-item__details {
    max-width: fit-content;
}
}
@media (min-width: 768px) {
    .self-cart-container p.label{
        font-size: 2rem;
    }
    .self-cart-container{
        padding-inline: 3%;
    }
    .self-cart-end-ctn {
        max-width: 300px;
    }
}