.delivery-action-btn{
    width: calc(100% - 16px);
    height: 30px;
    margin: 8px;
    border: none;
    font-weight: 600;
    cursor: pointer;
    color: #fff;
}
.delivery-action-btn:hover{
   background-color: #101553;
}
.delivery-pickup{
    background-color: #465378;
}
.delivery-delivered{
    background-color: #289e0b;
}