.token-display-completed-order-container {
  background-color: white;
  width: 100%;
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.1);

}
.token-display-completed-order-container:hover {
}
.token-display-completed-order-container .order-status-type-container {
  background-color: red;
  border: transparent;
}
.token-display-completed-order-container .token-display-status-header {
  color: white;
  font-size: 2em;
}

.token-display-completed-order-container .token-status-card-body ul li {
  color: red;
}
