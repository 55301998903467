/* LOGIN page */
.self-login-input-ctn{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2% auto;
    padding: 2%;
    max-width: 400px;
}
.self-login-body{
    display: flex;
    flex-wrap: wrap;
    position: relative;
}

/* Input component */ 


.self-order-input {
    color: #fff;
    font-size: 0.9rem;
    background-color: transparent;
    width: 100%;
    box-sizing: border-box;
    padding-inline: 0.5em;
    padding-block: 0.7em;
    border: none;
    border-bottom: var(--border-height) solid var(--border-before-color);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #f2f2f2;
    color: #000;
  }
  
  .self-order-input-border {
    position: absolute;
    background: var(--border-after-color);
    width: 0%;
    height: 2px;
    bottom: 0;
    left: 0;
    transition: width 0.3s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  }
  
  .self-order-input:focus {
    outline: none;
  }
  
  .self-order-input:focus + .self-order-input-border {
    width: 100%;
  }
  
  .self-order-form-control {
    position: relative;
    width: 100%;
    margin: 0.5em;
  }
  
  .self-order-input-alt {
    font-size: 1.2rem;
    padding-inline: 0.8em;
    padding-block: 0.5em;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .self-order-input-border-alt {
    height: 3px;
    background: linear-gradient(90deg, #ece90b 0%, #FFBF59 50%, #00b3ff 100%);
    transition: width 0.4s cubic-bezier(0.42, 0, 0.58, 1.00);
  }
  
  .self-order-input-alt:focus + .self-order-input-border-alt {
    width: 100%;
  }

  /* Button component */

.self-order-login-btn {
    outline: 0;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    background: #e11503;
    width: 100%;
    min-width: 200px;
    border: 0;
    border-radius: 4px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, .1);
    box-sizing: border-box;
    padding: 16px 20px;
    color: #fff;
    font-size: 1em;
    font-weight: 600;
    letter-spacing: 1.2px;
    text-transform: uppercase;
    overflow: hidden;
    cursor: pointer;
    margin: 2em 0;
  }
  
  .self-order-login-btn:hover {
    opacity: .95;
  }
  
  .self-order-login-btn .self-order-login-animation {
    border-radius: 100%;
    animation: ripple 0.6s linear infinite;
  }
  
  @keyframes ripple {
    0% {
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.1), 0 0 0 20px rgba(255, 255, 255, 0.1), 0 0 0 40px rgba(255, 255, 255, 0.1), 0 0 0 60px rgba(255, 255, 255, 0.1);
    }
  
    100% {
      box-shadow: 0 0 0 20px rgba(255, 255, 255, 0.1), 0 0 0 40px rgba(255, 255, 255, 0.1), 0 0 0 60px rgba(255, 255, 255, 0.1), 0 0 0 80px rgba(255, 255, 255, 0);
    }
  }



  .animated-border-button{
    width: fit-content;
    padding: 4px 20px;
    height: 30px;
    background: #d44040;
    position: relative;
    display: flex;
    place-content: center;
    place-items: center;
    overflow: hidden;
    border-radius: 6px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    margin: 4px 0;
    margin-left: auto;
  }
  .animated-border-button svg{
    z-index: 10;
  }
  .animated-border-button::before {
    content: '';
    position: absolute;
    width: 115%;
    background-image: linear-gradient(180deg, #e11503, #e9a602);
    height: 178%;
    animation: rotBGimg 2s linear infinite;
    transition: all 0.1s linear;
  }
  
  @keyframes rotBGimg {
    from {
      transform: rotate(0deg);
    }
  
    to {
      transform: rotate(360deg);
    }
  }
  
  .self-order-card::after {
    content: '';
    position: absolute;
    background: #ffffff;
    inset: 5px;
    border-radius: 15px;
  }  
