.cart-list-container {
  flex: 1;
  background-color: #ffff;
  padding: 1em;
  color: white;
}

.cart-list-table-wrapper {
  height: 98vh;
  overflow-y: auto;
  position: relative;
}
.cart-list-table-wrapper table {
  border-collapse: collapse;
  width: 100%;
}
.cart-list-table-wrapper table td,
.cart-list-table-wrapper table th {
  border: transparent;
}
.cart-list-table-wrapper table th {
  background: linear-gradient(to bottom, #00376e, #004ba9);
  padding: 1em;
  position: sticky;
  top: 0;
}
.cart-list-table-wrapper table td {
  color: black;
  padding: 0.5em;
}

.cart-list-table-wrapper table tr:nth-child(even) {
  background-color: #f0f0f0;
}
.cd-text-start{
  text-align: start;

}
.cd-tex-end{
  text-align: end;
}
.cart-list-table-wrapper table tfoot {
  position: sticky;
  bottom: 0;
  width: 100%;
}
.cart-list-table-wrapper table tfoot td {
  background: #00376e;
  color: #f0f0f0;
  font-size: 18px;
  text-align: end;
}
