.add-seat-container {
  padding: 0.5em;
}
.add-seat-wrapper {
  padding: 0.5em;
  height: 100vh;
  background-color: white;
}
.floor-container {
  margin-top: 0.5em;
}

.floor-container .floor-box {
  /* flex: 1; */
  height: 3em;
  text-align: center;
  color: white;
 
}
.add-seat-wrapper .button-container{
  position: absolute;
  top: 10%;
  right: 10px;
}
.add-seat-wrapper .button-container .btn{
  width: 100px;
    border-radius: 20px;
}


