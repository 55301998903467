.kot-side-menu-container {
  /* position: sticky; */
  /* top: 0; */
  transition: var(--transition);
  background-color: var(--background);
  color: var(--text-color);
  height: 90vh;
  /* min-width: 20em; */
  transition: all 0.5s ease-in-out;
}
.kot-side-menu-wrapper {
  padding: 1.5em;
}
.kot-side-menu-header h2 {
  margin: 0;
  min-width: 8em;
}
.kot-side-menu-list-container {
  padding-top: 1.5em;
  height: 75vh;
  overflow-y: auto;
  /* background-color: red; */
}

.kot-side-menu-list-container ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  width: 16em;
}
.kot-side-menu-list-container ul li {
  padding: 0.7em;
  transition: all 0.3s cubic-bezier(0.42, 0, 0.33, 0.99);
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
}
.kot-side-menu-list-container ul li:hover {
  background-color: var(--list-hover);
}
.kot-side-menu-list-container ul li {
  font-size: 13px;
  margin: 5px 0;
}

.kot-side-menu-list-container .kot-filter-selected {
  background-color: var(--list-hover);
  /* background-color: red; */
}

.kot-side-menu-footer button {
  padding: 0.5em;
  width: 100%;
  margin-top: 5px;
  background: transparent;
  color: var(--text-color);
  border: transparent;
  font-size: 13px;
  font-weight: 600;
  cursor: pointer;
  border-radius: 5px;
}

/* scroll  */
/* Hide the scrollbar by default */
.kot-side-menu-list-container {
  overflow: hidden;
}

/* Show the scrollbar when the container is hovered */
.kot-side-menu-list-container:hover {
  overflow-y: scroll; /* Show vertical scrollbar */
}

/* Customize scrollbar appearance */
.kot-side-menu-list-container::-webkit-scrollbar {
  width: 5px; /* width of the scrollbar */
}

.kot-side-menu-list-container::-webkit-scrollbar-thumb {
  background-color: var(--text-color); /* color of the thumb */
}

/* Ensure scrollbar is not shown when not hovered */
.kot-side-menu-list-container * {
  scrollbar-width: none;
}

/* Show scrollbar thumb when hovered */
.kot-side-menu-list-container:hover *::-webkit-scrollbar-thumb {
  background-color: var(--text-color); /* color of the thumb */
}
