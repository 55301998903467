:root {
  --text-color: #000000;
}

.kot-token-display-container {
  padding: 1.5em 0;
  background-color: white;


}
.token-display-body {
  display: flex;
  height: 95vh;
  overflow: hidden;
}

/* status headers  */
.order-status-type-container {
  /* width: 10em; */
  text-align: center;
  padding: 0.5em;
  border-bottom: 1px solid black;
}
.token-display-status-header {
  margin: 0;
  color: var(--text-color);
}
.token-status-card-body {
  height: 95vh;
  overflow-y: auto;
}
.token-status-card-body ul {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}
.token-number-box {
  padding: 0.5em;
  background-color: #e2e2e2;
  border-radius: 25px;
}
.token-status-card-body ul li {
  margin: 1em 0;
  font-size: 20px;
  font-weight: 600;
}
