.self-order-card {
    width: 230px;
    height: 200px;
    background: #fff;
    position: relative;
    display: flex;
    flex-direction: column;
    place-content: center;
    place-items: center;
    overflow: hidden;
    border-radius: 20px;
    padding: 10px 4px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
    cursor: pointer;
  }
  
  .self-order-card::before {
    content: '';
    position: absolute;
    width: 140px;
    background-image: linear-gradient(180deg, #38a8d2, #ff8d87);
    height: 150%;
    animation: rotBGimg 5s linear infinite;
    transition: all 0.2s linear;
  }
  
  @keyframes rotBGimg {
    from {
      transform: rotate(0deg);
    }
  
    to {
      transform: rotate(360deg);
    }
  }
  
  .self-order-card::after {
    content: '';
    position: absolute;
    background: #ffffff;
    inset: 5px;
    border-radius: 15px;
  }  



  .self-order-card img{
    z-index: 20;
    width: 50%;
    min-height: 130px;
    margin-top: 10px;
  }
  .self-order-card h4{
    z-index: 20;
    margin: 0;
    color:#e11503;
    margin-top: auto;
    margin-bottom: 10px;
  }