.pay-head-container {
  padding: 0.5em;
}
.pay-head-wrapper {
  padding: 1em;

  background-color: white;
  min-height: 100vh;
}
.pay-head-wrapper .head-name {
  padding: 1em 0;
}
.pay-head-wrapper .card-container {
  display: flex;
  /* grid-template-columns: repeat(auto, 10em); */
  /* justify-content: space-between; */
  gap: 2em;
  flex-wrap: wrap;
}
.pay-head-wrapper .pay-head-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.pay-head-wrapper .pay-head-btn-cntr .pay-head-btn {
  padding: 1em;
  background-color: blue;
  color: white;
  border: transparent;
  border-radius: 0.5em;
  cursor: pointer;
  font-weight: 600;
}
.pay-head-dialog {
  display: flex;
  gap: 0.5em;
  flex-wrap: wrap;
  padding: 3em 2em !important;
}
.pay-head-dialog .new-global-single-input {
  width: 30%;
}
