.list-notes-ctr {
    display: flex;
    gap: 1px;
    max-width: 590px; 
    overflow-x: auto;
    padding: 10px;
    white-space: nowrap;
    align-items: center;
    justify-content: flex-start; 
    position: relative;
  }
  
  .list-notes-ctr .items {
    display: inline-flex;
    align-items: center;
    background-color: #139109;
    color: #ffffff;
    padding: 1px 6px;
    border-radius: 10px;
    margin-right: 10px;
    cursor: pointer;
  }
  
  .list-notes-ctr .cross-icon {
    color: #ffffff;
    margin-left: 10px;
    cursor: pointer;
  }
  
  .list-notes-ctr::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  
  .list-notes-ctr::-webkit-scrollbar-thumb {
    background-color: #000000;
    border-radius: 18px;
  }
  
  .list-notes-ctr::-webkit-scrollbar-track {
    background-color: transparent;
  }
  
  .scroll-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: #139109;
    border: none;
    color: white;
    cursor: pointer;
    padding: 5px;
    z-index: 1;
  }
  
  .scroll-button.left {
    left: 0;
  }
  
  .scroll-button.right {
    right: 0;
  }
  