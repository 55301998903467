.advertisement-container-cd {
  flex: 0 0 40%;
  background: linear-gradient(to bottom, #00376e, #004ba9);
  color: white;
  display: flex;
  flex-direction: column;
}

.restaurant-info {
  margin-top: 1em;
  display: flex;
  justify-content: space-between;
  padding: 1em;
  border-bottom: 1px solid rgb(255, 255, 255);
}

.restaurant-info.column {
  flex-direction: column;
  justify-content: unset;
  text-align: center;
}

.restaurant-info .cd-header {
  font-size: 1.5em;
  font-weight: 600;
  display: block;
}
.cd-ph {
  font-size: 0.8em;
  font-weight: 600;
}

.cd-image-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.cd-image-wrapper.column {
  justify-content: center;
  margin-top: 1em;
}
.cd-image-container {
  width: 6em;
  height: 3em;
  overflow: hidden;
  /* background-color: red; */
  border-radius: 5px;
}
.cd-image-container img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.cd-animated-img {
  text-align: center;
  margin-top: 2em;
}

/* show advertisement  */
.cd-advertisement-img-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.cd-advertisement-img-wrapper > div {
  padding: 0.5em;
}
.cd-advertisement-image {
  height: 18vh;
  width: 38vw;
  overflow: hidden;
  border-radius: 10px;
}
.cd-advertisement-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
