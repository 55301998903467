.login-new-main-container {
    height: calc(100vh);
    overflow-y: hidden;
    /* padding-top: 30px; */
    display: flex
;
    flex-direction: column;

}

.login-new-header-container {
   
    display: flex;
    justify-content: space-between;
    padding-inline: 60px;

}

.logo {
    height: 9vh;
}

.rest-logo {
    height: 13vh;
}

/* Left section (login form) */
.login-new-main-left-container {
    margin-top: auto;
    margin: 0 auto;
    align-content: center;
    /* margin-top: auto; */
    /* width: 40%; */
    flex: 1 1;
    max-width: 500px;
}

.login-new-form-container {
    display: flex;
    margin:20px 0% 0% 3%;
}

.login-new-username-wrapper {
    /* height: 60px; */
    width: 98%;
    border-radius: 16px;
    margin: 20px auto;
    border: 3px solid rgb(14 14 14);
    display: flex;
    align-items: center;
    position: relative;
    padding: 0;
}

.login-new-username-wrapper input {
    width: 100%;
    height: 30px;
    padding: 8px 8px 8px 24px;
    border-radius: 22px;
    font-size: 1rem;
    border: none;
    outline: none;
    background-color: transparent;
}

.login-new-username-wrapper input:-webkit-autofill {
    background-color: transparent !important; /* or set it to match your design */
    height: auto;
    box-shadow: 0 0 0px 1000px #ffffff00 inset !important; /* Ensures background resets */
    transition: background-color 5000s ease-in-out 0s !important; /* Prevents flickering */
  }
.login-new-container{
    display: flex;
    justify-content: space-between;
    flex: 1;
    margin-top: auto;

}
.login-new-btn {
    width: 100%;
    height: 50px;
    font-size: 1.2rem;
    margin: 20px 0;
    border-radius: 24px;
    border: none;
    color: #fff;
    background-color: #87C683;
    box-shadow: 0 1px 5px rgb(154, 179, 171);
    cursor: pointer;
}

.login-new-content-wrapper-forgot-password-register {
    width: 100%;
    display: flex;
    align-items: center;
    margin: 23px 12px;
    font-weight: 700;
    cursor: pointer;
    justify-content: space-between;
}


.image-scroll-container {
    display: flex;
    gap: 20px; 
    transition: transform 0.5s ease; 
}

/* Default image styling */
.image-item {
    width: 213px;
    height: 213px;
    background-size: cover;
    background-position: center;
    border-radius: 10px;
    transition: all 0.5s ease;
    filter: blur(2px);
    transform: scale(0.8);
    cursor: pointer;
    z-index: 1;
}

/* Style for the central (highlighted) image */
.image-item.center {
    width: 300px; 
    height: 295px;
    filter: none; 
    transform: scale(1); 
    z-index: 2; 
}


.image-item:hover {
    opacity: 0.9;
}


.image-scroll-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    transition: transform 0.5s ease;
    overflow-x: hidden;
    
}


.login-new-main-right-container {
    background-image: url(./../../Assets/SVG/Rectangle\ 1107.svg);
    background-position: center;
    background-size: contain; 
    background-repeat: no-repeat;
    width: 47%;
    height: 83vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 0; 
    overflow: visible; 
}

.login-ctn-mobile-bg{
    background-color: #014e2a;
    width: 100%;
    position: relative;
    padding-top: 100px;
}
.login-btn-disabled {
    width: 100%;
    height: 45px;
    font-size: 1rem;
    margin: 20px 0;
    /* border-radius: 24px; */
    border: none;
    color: rgb(174, 174, 174);
    background-color: #eeeeee;
    cursor: not-allowed;
}
@media (max-width: 1099px){
    
    .image-scroll-container {
        
        gap: none;
       
    }
    .image-item{
        width: 198px;
        height: 131px;
    }
    .image-item.center{
        width: 293px;
        height: 173px;
    }
    .login-new-form-container {
        padding-inline: none;
       
    }
    .user-name-container{
        position: relative;
    }
}

@media (max-width:768px){
    .login-new-main-container{
        padding-top: 0;
        height: 100vh;
    }
    .login-new-header-container {
        min-height: 204 px;
        align-items: center;
    }
    .image-scroll-container{
        position: absolute;
        top: -100px;
        left: 50%;
        width: 100%;
        transform: translateX(-50%);
    }
    .image-scroll-container .image-item {
        width: 26%;
        height: 157px;
    }
    .image-scroll-container   .image-item.center {
        width: 155px;
        height: 200px;
    }
    .login-new-username-wrapper input{
        color: #fff!important;
    }
}

.login-text-container .login-text-mobile {
    margin-left: 0;
    font-size: 1.7rem;
    font-weight: 500;
    color: #fcfcfc;
}
.login-text-container .login-description-mobile {
    margin-left: 0;
    font-size: 0.7rem;
    font-weight: 500;
    color: #fcfcfc;
}

.login-new-header-container {
    min-height: 130px;
    align-items: center;
    max-width: 1200px;
    margin: auto;
    width: 85%;
}
.login-new-main-left-container {
    place-items: center;
    height: 87vh;
}
.login-new-main-left-container-mobile{
    padding-inline: 20px;
    flex-direction: column;
    display: flex;
    gap: 20px;
    max-width: 500px;
    margin: 0 auto;
} 
.user-name-container-mobile {
    margin-right: 0;
    width: 100%;
    position: relative;
}
.user-name-container-mobile .login-new-username-wrapper{
    height: 38px;
    width: 100%;
    border-radius: 0;
    margin: 20px auto;
    border: 1px solid rgb(252 252 252);
    
}
.user-name-container-mobile .login-new-username-wrapper input::placeholder{
    color: white;
    font-size: 0.7rem;
}
.login-new-content-wrapper-forgot-password-register-mobile{
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    font-size: x-small;
}
.login-new-btn-mbl{
    width: 100%;
    height: 45px;
    font-size: 1rem;
    margin: 10px 0;
    /* border-radius: 24px; */
    border: none;
    color: #000000;
    background-color: #ffffff;
    box-shadow: 0 1px 5px rgb(154, 179, 171);
    cursor: pointer;
} 

.login-new-header-container-mobile {
    min-height: 168px;
    justify-content: center;
    place-items: start;
    padding-top: 29px;
    align-self: center;
}