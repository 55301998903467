/* Parent Page */

.self-order-parent-ctn{
    height: 100vh;
    width: 100%;
    background-color: #fff;
    position: relative;
    text-align: center;
}


/* Login section */

.self-login-ctn {
    height: 100%;
    background-image: url("../../Assets/SelfOrder/selfBg.svg");
    background-size: cover;
    background-position: center bottom; 
    background-repeat: no-repeat;
    animation: slideBackground 25s infinite alternate linear; 
    overflow: hidden; 
    position: relative;
    text-align: center;
  }

  .self-topbar-ctn .search-ctn input{
    font-size: 16px;
  }
  
  @keyframes slideBackground {
    0% {
      background-position: 0% 210px;
    }
    100% {
      background-position: 80% 210px; 
    }
  }
  
  .self-back-arrow-btn{
    position: absolute;
    top: 20px;
    left: 3%;
  }
  .self-menu-btn{
    position: absolute;
    top: 20px;
    right: 3%;
  }
  .self-register-input-ctn{
    
    display: flex;
    flex-wrap: wrap;

  }

  /* self order footer */

  .self-order-footer-ctn{
    background-color: #e7e7e7;
    position: fixed;
    bottom: 0;
    width: 100%;
    left: 0;
    display: grid;
    grid-template-columns: 1fr 2fr;
    padding: 5px 0;
  }
  
  .self-order-footer-ctn .logo-ctn img.company-logo,.logo-ctn img.eyet-logo{
    width: 60px;
  }
  .self-order-footer-ctn .logo-ctn{
    align-items: center;
    gap: 3px;
  }
  .self-order-footer-ctn .powered-by-ctn{
    display: flex;
    flex-direction: row;
    gap: 5px;
    padding-left: 4px;
  }
  .self-order-footer-ctn .powered-by-ctn p{
    margin: 0;
    font-size: 0.7rem;
    text-wrap: nowrap;
  }


  .footer-btn-container {
    display: flex;
    gap: 10px;
    width: 100%;
    max-width: 230px; 
    margin-left: auto;
    padding: 10px;
    justify-content: flex-end;
  }
  
  /* Button Styles */
  .self-order-button {
    padding: 8px 10px;
    font-size: 14px;
    border-radius: 1px;
    cursor: pointer;
    transition: all 0.3s ease;
    width:calc( 50% - 5px); 
    font-weight: bold;
  }
  
  /* Back Button (outlined) */
  .footer-btn-container .btn-back {
    background-color: white;
    color: black;
    border: 2px solid black;
  }
  
  /* Next Button (filled with color) */
  .footer-btn-container .btn-next {
    background-color: #e11503;
    color: white;
    border: none;
  }
  
  /* Hover Effects */
  .footer-btn-container .btn-back:hover {
    background-color: black;
    color: white;
  }
  
  .footer-btn-container .btn-next:hover {
    background-color: #b91001;
  }
  
  /* Text and Responsive elements */

  .self-order-parent-ctn h3 {
    font-size: 2rem; 
  }
  .self-order-parent-ctn h4 {
    font-size: 1.6rem; 
  }
  .self-order-parent-ctn p {
    font-size: 0.9rem; 
  }

  .self-order-parent-ctn p.skip {
    font-size: 1.2rem; 
    color: #e11503;
    cursor: pointer;
    font-weight: 600;
    margin: 0;
  }
  .self-order-parent-ctn p.skip:hover,.self-order-parent-ctn p.red-action:hover {
    color: #ff1500;
  }
  .self-order-parent-ctn p.red-action{
    margin: 0;
    text-align: end;
    margin-left: auto;
    color: #e11503;
    font-weight: 600;
    cursor: pointer;
    font-size: 0.9rem;
  }
  
  .responsive-arrow-icon{
    color: #000;
    font-size: 24px!important;
  }

  

  /* top-bar section */

  .self-topbar-ctn{
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #e11503;
    min-height: 106px;
  }
  .self-topbar-ctn .self-menu-btn{
    position: inherit;
  }
  .self-topbar-ctn .self-menu-btn button{
    border: none!important;
    padding: 0;
  }
  .self-topbar-ctn .self-menu-btn svg{
    color:#fff
  }
  .self-topbar-ctn .customer-ctn h4{
    color:#fff
  }
  .self-topbar-ctn .table-ctn{
    background-color: #fff;
    border-radius: 30px;
    gap: 6px;
    padding: 0 10px;
}
  .self-topbar-ctn .table-ctn p{
    margin: 0;
}
  .self-topbar-ctn .search-ctn{
    border-radius: 30px;
    border: none;
    padding: 2px;
    padding-left:10px ;
    background-color: #fff;
    min-width: 30px;
    position: relative;
}
  .self-order-active-span{
    content: "";
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #9e9e9e;
    color: #fff;
    position: absolute;
    top: -10px;
    right: -6px;
    font-size: 11px;
  }
  .self-topbar-ctn .search-ctn input{
    width: 90%;
    border: none;
    max-width: 120px;
    border-radius: 10px;
    transition: width 0.3s ease-in-out;
}
  .self-topbar-ctn .search-ctn p{
    margin: 0 5px;
    font-weight: 600;
}
.self-topbar-ctn .justify-center{
  justify-content: start;
}
.self-topbar-ctn .cart-ctn{
  justify-content: space-between;
}

.self-order-middle-container{
  height: calc(100vh - 199px);
  display: flex;
  position: relative;
}

@media(max-width:500px){
  .text-over-flow {
    white-space: nowrap;
    overflow: hidden; 
    text-overflow: ellipsis;   
    max-width: 125px;   
    display: inline-block;
  }
}
  @media (min-width: 768px) {
    .self-order-parent-ctn h3 {
      font-size: 2.5rem;
    }
    .self-order-parent-ctn h4 {
      font-size: 1.9rem; 
    }
    .self-order-parent-ctn p.skip {
      font-size: 1.5rem;
    }
    .self-order-parent-ctn p.red-action{
      font-size: 1rem;
    }
    .self-register-input-ctn{
      width: 600px;
    }
    .self-register-input-ctn .self-order-form-control{
      width: calc( 50% - 1em);
    }
    .responsive-arrow-icon{
      font-size: 36px!important;
    }
    .footer-btn-container{
      max-width: 400px;
    }
    .self-order-button{
      padding: 10px 20px;
      font-size: 16px;
    }
    .self-order-parent-ctn p {
      font-size: 1rem; 
    }
    .self-topbar-ctn .search-ctn{
      max-width: 230px;
    }
    .self-topbar-ctn .table-ctn{
      min-width: 100px;
    }
    .self-topbar-ctn .cart-ctn{
      padding-right: 15px;
    }
    .self-order-middle-container {
      height: calc(100vh - 218px);
    }
    .self-order-active-span{
      width: 20px;
      height: 20px;
      top: -12px;
      right: -4px;
      font-size: 14px;
      padding: 3px;
    }
  }
  
  @media (min-width: 1024px) {
    .self-order-parent-ctn h3 {
      font-size: 3rem;
    }
    .self-order-parent-ctn h4 {
      font-size: 2rem; 
    }
    .responsive-arrow-icon{
      font-size: 48px!important;
    }
    .self-order-footer-ctn .logo-ctn img.company-logo,.logo-ctn img.eyet-logo{
      width: 80px;
    }
    .self-order-parent-ctn p {
      font-size: 1.2rem; 
    }
    .self-topbar-ctn .search-ctn{
      min-width: 210px;
    }
    .self-order-middle-container {
      height: calc(100vh - 250px);
    }
  }

  @media (max-height: 800px) and (min-width: 768px) {
    .self-order-parent-ctn h3 {
      font-size: 1.5rem;
    }
    .self-order-parent-ctn h4 {
      font-size: 1.2rem; 
    }
  }

