@media print {
    .page {
      page-break-inside: avoid;
    }
  main{
    margin-top: 10px!important;
  }
    header {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      height: 50px;
    }
  
    footer {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      height: 50px;
    }
  }
  