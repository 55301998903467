/* floor plan */
.self-order-floor-parent{
    padding: 10px 20px;
    padding-right: 10px;
}
.self-order-parent-ctn .floor-container{
    margin: 0;
}
.self-order-parent-ctn .tab-panel-container{
    margin: 0;
    height: calc(100vh - 260px);
}
.self-order-parent-ctn .tab-panel-container .sub-tab-wrapper{
    padding: 0;
}

@media(min-width: 1024px){
    .self-order-parent-ctn .tab-panel-container{
        margin: 0;
        height: calc(100vh - 300px);
    }
}