.carousel {
    position: relative;
    width: 100%;
    max-height: 75vh;
    overflow: hidden;
  }
  
  .carousel-inner {
    display: flex;
    transition: transform 0.5s ease-in-out;
  }
  
  .carousel-item {
    flex: 0 0 100%;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    display: block;
    max-height: 80vh;
    overflow-y: hidden;
  }
  
  .carousel-item.active-adv {
    opacity: 1;
  }
  
  .carousel-item img,
  .carousel-item video {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  
  .carousel-control {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 24px;
    padding: 8px 16px;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    border: none;
    cursor: pointer;
  }
  
  .carousel-control.prev {
    left: 16px;
  }
  
  .carousel-control.next {
    right: 16px;
  }
  
  .upload-add-header .css-k008qs      span{
    width: 25px !important;
    height: 25px !important;
  }