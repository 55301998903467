.button-icon-hover {
    --width: 100px;
    --height: 35px;
    --tooltip-height: 35px;
    --tooltip-width: 90px;
    --gap-between-tooltip-to-button: 18px;
    --button-color: #1163ff;
    --tooltip-color: #fff;
    width: var(--width);
    height: var(--height);
    background: var(--button-color);
    position: relative;
    text-align: center;
    border-radius: 0.45em;
    font-family: "Arial";
    transition: background 0.3s;
    cursor: pointer;
  }
  
  .button-icon-hover::before {
    position: absolute;
    content: attr(data-tooltip);
    width: var(--tooltip-width);
    height: var(--tooltip-height);
    background-color: var(--tooltip-color);
    font-size: 0.9rem;
    color: #111;
    border-radius: .25em;
    line-height: var(--tooltip-height);
    bottom: calc(var(--height) + var(--gap-between-tooltip-to-button) + 10px);
    left: calc(50% - var(--tooltip-width) / 2);
  }
  
  .button-icon-hover::after {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-top-color: var(--tooltip-color);
    left: calc(50% - 10px);
    bottom: calc(100% + var(--gap-between-tooltip-to-button) - 10px);
  }
  
  .button-icon-hover::after,.button-icon-hover::before {
    opacity: 0;
    visibility: hidden;
    transition: all 0.5s;
  }
  
  .button-icon-hover .text {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .button-icon-hover .button-wrapper,.button-icon-hover .text,.button-icon-hover .icon {
    overflow: hidden;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    color: #fff;
  }
  
  .button-icon-hover .text {
    top: 0
  }
  
  .button-icon-hover .text,.button-icon-hover .icon {
    transition: top 0.5s;
  }
  
  .button-icon-hover .icon {
    color: #fff;
    top: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .button-icon-hover .icon svg {
    width: 24px;
    height: 24px;
  }
  

  
  .button-icon-hover:hover .text {
    top: -100%;
  }
  
  .button-icon-hover:hover .icon {
    top: 0;
  }
  
  .button-icon-hover:hover:before,.button-icon-hover:hover:after {
    opacity: 1;
    visibility: visible;
  }
  
  .button-icon-hover:hover:after {
    bottom: calc(var(--height) + var(--gap-between-tooltip-to-button) - 20px);
  }
  
  .button-icon-hover:hover:before {
    bottom: calc(var(--height) + var(--gap-between-tooltip-to-button));
  }
  


  /* add  button */
.add-btn-global-new{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 40px;
  height: 40px;
  border-radius: calc(40px / 2);
  border: none;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition-duration: .3s;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.199);
  background: linear-gradient(144deg, #72f47f, #21c214 50%, #519a58);
}

.add-btn-global-new .sign {
  width: 100%;
  font-size: 1.75rem;
  color: white;
  transition-duration: .3s;
  display: flex;
  align-items: center;
  justify-content: center;
}
.add-btn-global-new .text {
  position: absolute;
  right: 0%;
  width: 0%;
  opacity: 0;
  color: white;
  font-size: 0.9rem;
  font-weight: 500;
  transition-duration: .3s;
}
/* hover effect on button width */
.add-btn-global-new:hover {
  width: 125px;
  transition-duration: .3s;
}

.add-btn-global-new:hover .sign {
  width: 30%;
  transition-duration: .3s;
  padding-left: 15px;
}
.add-btn-global-new:hover .text {
  opacity: 1;
  width: 70%;
  transition-duration: .3s;
  padding-right: 15px;
}
/* button click effect*/
.add-btn-global-new:active {
  transform: translate(2px ,2px);
}