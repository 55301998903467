.tab-panel-container {
  margin-top: 1em;
  height: 75vh;
  /* width: 77vw; */
}
.tab-panel-container .sub-tab-wrapper {
  position: relative;
  height: 100%;
  width: 100%;
  overflow-x: auto;
  padding: 1em;
}

.tab-panel-container .sub-tab-wrapper .shape-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  border: 1px solid #ccc;
  transition: all  300ms ease-in-out;
}

.tab-panel-container .Ellipse {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.tab-panel-container .Polygon {
  width: 100px;
  height: 58px;
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
}
.tab-panel-container .selected {
  padding: 1px;
  /* background-color: #e91d1d !important; */
  border: 2px solid #e91d1d !important;
}

/*shape*/
.tab-panel-container .LineSegmentTool{
  /* width: 3%;
  height: 13em; */
  /* background-color: #3498db; */
  /* margin: 20px; */
  transform: rotate(45deg) !important;
}
.not-found-container{
  display: flex;
  justify-content: center;
  align-items: center;
}

/* ----- */
.context-menu {
  position: absolute;
  background-color: white;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
  padding: 5px;
  border-radius: 3px;
}

.context-menu-item {
  cursor: pointer;
  padding: 5px 10px;
}

.context-menu-item:hover {
  background-color: #f0f0f0;
}



