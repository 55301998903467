.self-feedback-ctn{
    /* max-height: calc(100% - 110px); */
    overflow-y: auto;
}

.self-feedback-first-main {
    width: 100%;
    justify-items: center;
    align-content: center;

}

.feedback {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.self-feedback-secound-main {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    text-align-last: center;
}

.self-feedback-content {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 65px;
}

.self-feedback-content h1 {
    font-size: 1.10rem;
    font-weight: 500;
    margin: 0;
    text-align: start;
}

.self-feedback-content h3 {
    font-size: 2rem;
    background-color: #e91e1e;
    color: white;
    font-weight: 500;
    padding-inline: 18px;
    margin: 10px 0;
}

.stardiv {
    display: table-row;
    align-content: center;
    margin-top: 16px;
}

.star-rating {
    display: flex;
    flex-direction: row-reverse;
    font-size: 3em;
    justify-content: center;
    padding: 20px 10px;
}

.star-rating input {
    display: none;
}


.star-rating label {
    color: #ddd;
    cursor: pointer;
    transition: color 0.2s ease;
}

.star-rating input:checked~label,
.star-rating label:hover,
.star-rating label:hover~label {
    color: #ffd700;
    /* Gold color for selected stars */
}
.feedback-textarea-div{
    width: 100%;
}
.feedback-textarea {
    width: 63%;
    padding: 10px;
    margin-top: 10px;
    border: 2px solid #ccc;
    border-radius: 2px;
    font-size: 1.50em;
    resize: vertical;
    text-align-last: start;
    width: fit-content;
}

.QRcodeDiv {
    flex-direction: column;
    display: flex;
    gap: 14px;
    justify-content: center;

}

.qrcode {
    height: 100px;
    width: 100px;
    margin-top: 20px;
}

.self-btn {
    padding: 2px 25px 2px 25px;
    color: blue;
    font-weight: 500;
}


.price-breakdown-container {
    background: #f9f9f9; /* Light background for contrast */
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
    margin: auto;
    width: 90%;
    max-width: 450px;
    text-align: center;
    font-family: 'Arial', sans-serif;
  }
  
  .price-breakdown-container h2 {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 10px;
    border-bottom: 2px solid #e0e0e0;
    padding-bottom: 10px;
  }
  
  .price-details {
    margin-top: 15px;
  }
  
  .price-item {
    display: flex;
    justify-content: space-between;
    padding: 8px 0;
    font-size: 1rem;
    color: #555;
  }
  
  .price-item span:first-child {
    font-weight: 500;
  }
  
  .price-item.total {
    font-size: 1.2rem;
    font-weight: bold;
    color: #27ae60;
    display: flex;
    flex-direction: row;
    justify-content: end;
    gap: 40px;
}
  
  .price-item.total span:last-child {
    text-align: right;
  }

  @media (max-width:600px){
    .order-confirm-screen .self-order-card{
    width: 150px;
    height: 135px;
  }
  .order-confirm-screen .self-order-card h4{
    font-size: 1rem;
  }
  }
  

@media (min-width: 768px) {
    
    .feedback{
        gap: 50px;
        justify-content: center;
    }
    .self-feedback-content h1 {
        font-size: 1rem;
    }
    
    .sself-feedback-content h3 {
        font-size: 2rem;
        margin: 40px 0;
    }
    
    
    .star-rating {
        display: flex;
        flex-direction: row-reverse;
        font-size: 3em;
    }
    
    .self-feedback-secound-main {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        /* gap: 30%; */
        padding-inline: 40px;
        align-items: center;
    }
    
    .QRcodeDiv {
        gap: 23px;
        
    }
    .self-feedback-content h1 {
        
        font-size: 1.20rem;
    }
    
    .self-btn {
        padding: 4px 35px 4px 35px;
    }
    
    .stardiv {
        margin-top: 0px;
    }
    .star-rating {
        display: flex;
        flex-direction: row-reverse;
        font-size: 4em;
    }
    .price-breakdown-container {
      padding: 15px;
    }
    
    .price-item {
      font-size: 0.9rem;
    }
    
    .price-item.total {
      font-size: 1.1rem;
    }
}

@media (min-width: 1024px) {
    .self-feedback-content h1 {
        font-size: 1.60rem;
    }
    .self-feedback-content h1 {
        font-size: 1.40rem;}

    .self-feedback-content h3 {
        font-size: 3rem;
    }

    .QRcodeDiv h1 {
        font-size: 1.20rem;
    }
}