.kot-popup-container {
  padding: 1em;
}
.kot-cancel-reason-container {
  padding: 1em 0;
  margin-block-end: 1em;
}
.kot-cancel-head {
  color: red;
  text-align: center;
  margin: 0;
}
