.customer-display-container {
  display: flex;
  justify-content: center;
  height: 100vh;
  /* background-color: red; */
  overflow-y: auto;
}
.add-advertisement-card-cd {
  background-color: white;
  padding: 1em;
  color: black;
  flex: 1;
  overflow-y: auto;
}

@media screen and (max-width: 1440px) {
  .add-advertisement-card-cd .upload-advertisement-body,
  .add-advertisement-card-cd .upload-add-header {
    width: 36.3vw;
  }
}
