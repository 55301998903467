.pos-receipt-model-selection-container {
  padding: 1.5em;
  background-color: white;
  height: 85vh;
  overflow-y: auto;

  display: flex;
  flex-wrap: wrap;
  gap: 1%;
}
.pos-receipt-model-card {
  transition: all 0.3s ease-in-out;

  display: flex;
  flex-direction: column;
}

.pos-receipt-model-header [type="checkbox"] {
  margin-right: 5px;
}

.pos-receipt-model-image {
  width: 250px;
  height: calc(250px * (6 / 5));
  overflow: hidden;
}

.pos-receipt-model-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
