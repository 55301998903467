.category-list-header{
    display: flex;
    background-color: #fff;
  }
  .category-list-header .arrow-box{
    position: relative;
    /* padding: 4px 0; */
    background-color: #fff;
    min-width: 35px;
    width: auto;
    height: 25px;
  }
  .category-list-header .arrow-para{
    margin-left: 15px;
  }
  .category-list-header .arrow-box .justify-center{
    z-index: 28;
    position: inherit;
    background-color: #fff;
  }
  .category-list-header .arrow-box .justify-center p{
    margin: 0;
    color: #795547;
    font-size: 0.65rem;
    font-weight: 600;
    padding-left: 5px;
    cursor: pointer;
  }
  .category-list-header .arrow-box .justify-center p:hover{

    color: #78000074;
  }
  .category-list-header .arrow-box .cone-shape{
    position: absolute;
    top: 50%;
    right: -10px;
    width: 20px;
    height: 20px;
    transform: translateY(-50%) rotate(45deg);
    background-color: #fff;
    z-index: 8;
    border:1px solid #000;
    border-left: none;
    border-bottom: none;
    content: "";
  }
  
  /* filterbutton css */
  .filter-icon-ctn{
    height: 30px;
    gap: 8px;
    cursor: pointer;
    background-color: #f3f5ff;
    padding: 8px;
    border-radius: 3px;
    color: rgb(92, 92, 229);
    font-weight: 600;
    font-size: 0.9rem;
    position: relative;
  }
  .filter-icon-ctn-drop-down{
    width: 200px;
    position: absolute;
    top: 115%;
    /* left: -75%; */
    right: 10%;
    display: flex;
    flex-direction: column;
    font-size: 0.9rem;
    padding: 7px 10px;
    color: #000;
    cursor: pointer;
    background-color: #f3f5ff;
    z-index: 10;
    box-shadow: 2px 3px 4px #4c3d3d;
  }
  .filter-icon-ctn-drop-down::before {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #f3f5ff;
    top: -5px;
    content: "";
    height: 0;
    left: 95%;
    margin-left: -24px;
    position: absolute;
    width: 0;
  }
  .filter-span-count{
    position: absolute;
    top: -5px;
    right: -5px;
    height: 15px;
    width: 15px;
    border-radius: 50%;
    background-color: blue;
    color: #fff;
    z-index: 20;
    font-size: 0.8rem;
  }
 .filter-icon-ctn-drop-down div.filter-body{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 0.9rem;
    padding: 7px 0px;
    color: #000;
    direction: ltr;
    cursor: pointer;
  }
  
 .filter-icon-ctn-drop-down div.filter-body p{
    /* font-weight: 600; */
    font-size: 0.9rem;;
  }
 .filter-icon-ctn-drop-down div.filter-body .MuiInputBase-root{
    padding: 0 9px;
  }
 .filter-icon-ctn-drop-down div.filter-body label{
    top: 50%;
    transform: translateY(-50%);
    left: 10px;
  }
 .filter-icon-ctn-drop-down div.filter-body label.MuiInputLabel-shrink{
    top: 0;
    left: 15px;
    color: rgb(92, 92, 229);
    font-size: 0.7rem;
  }
 .filter-icon-ctn-drop-down div.filter-body fieldset{
    border: none;
  }
 .filter-icon-ctn-drop-down  button.btn-secondary{
    margin-top: 25px;
    margin-bottom: 6px;
    color: #fff!important;
    height: 30px;
    border-radius: 4px;
  }
  
 /* timer ctn */
 .timer-ctn{
    width: 25px;
    height: 25px;
    border-radius: 9px;
    background-color: #fff;
    font-size: 0.7rem;
 }


 /* kot list css */

 .kot-list-ctn{
    background-color: #fff !important;
    width: 35vw;
    max-width: 600px;
    min-width: 480px;
    box-shadow:2px 2px 3px 1px rgba(128, 128, 128, 0.5);
    border: 1px solid #dd1b1b;
 }
 .kot-list-ctn::before{
  border-bottom: 5px solid #ff0008;
 }

 .billing-kot-list-body{
  display: flex;
  flex-direction: column;
  max-height: 70vh;
  overflow-y: auto;
  gap: 10px;
 }

 .kot-order-card{
  display: flex;
  flex-direction: column;
  border: 1px solid #bebcbc;
  padding: 10px 15px;
 }

 .billing-kot-list-body::-webkit-scrollbar {
  width: 3px;
  height: 12px;
}

.billing-kot-list-body::-webkit-scrollbar-thumb {
  background-color: #a8a5a5;
}

.billing-kot-list-body::-webkit-scrollbar-track {
  background-color: #d6d6d6;
}
 .kot-order-card p{
  margin: 0;
  font-weight: 500;
  font-size: 1rem;
 }
 .kot-order-card .status{
  width: 90px;
  padding: 6px;
  text-align: center;
 }
.kot-order-card .status-blue{
  color: #0909ad;
  background-color: #e8e4ff;
}
.kot-order-card .status-red{
  background-color: #fde3e2;
  color: #e72424;
}
.kot-order-card .status-green{
  color: #068b35;
  background-color: #bdf7c4;
}

.kot-order-item-list{
  padding: 1px 60px;
  border-top: 1px solid #e7e7e7;
}
.kot-order-item-list p{
  font-size: 0.9rem;
}

@media(max-height:810px){
  .billing-kot-list-body{
    max-height: 60vh;
   }
}


@media(max-width:1250px){
  .category-list-header .arrow-box{
    min-width: 17px;
  }
  .category-list-header .arrow-box .justify-center p {
    font-size: 0.5rem;
  }
}



/* customer list button  */

.customer-list-btn{
  width: 100%;
  justify-content: space-between;
  align-items: center;
  text-align: start;
  border-left: 1px solid #fff;
  font-size: 0.9rem;
  padding-left: 10px;
  padding-right: 4px;
}

.customer-list-menu-items{
  width: 30vw;
  min-width: 450px;
  max-width: 500px;
  left: 0%;
  top: 40px;
  background-color: #fff !important;
  box-shadow: 2px 2px 3px 1px rgba(128, 128, 128, 0.5);
  border: 1px solid #159132;
  display: flex;
  flex-direction: column;
  font-size: 0.9rem;
  padding: 7px 10px;
  color: #000;
  cursor: pointer;
  position: absolute;
  z-index: 55;
  border-radius: 4px;
}

.customer-list-menu-items .billing-search-bar{
  width: 100%;
  padding: 10px 5px;
  background-color: #e7e7e7;
  border-radius: 4px;
  margin-bottom: 5px;
}
.customer-list-menu-items .billing-search-bar input{

  background-color: #e7e7e7;
  caret-color: #000;
  color: rgb(92, 87, 87);
  padding-left: 5px;
}
.customer-list-menu-items .kot-order-card{
  border: none;
  border-bottom: 1px solid #bebcbc;
  padding: 5px;
}

.customer-list-info-ctn{
  width: 100%;
}
.customer-list-info-ctn:hover{
    transform: scale(1.05);
  padding-left: 20px;
  background-color: #e7e7e7;
}

.customer-list-info-ctn .flex-column{
  align-items: start;
  width: 100%;
  max-width: none;
}
.customer-list-info-ctn .justify-space-between p{
  font-size: 0.9rem;
  text-align: start;
  width: 33.33%;
  color: #4b4949;
}
.selected-customer-from-list{
  border-bottom: 2px solid green!important;

}
.selected-customer-from-list p{
  color: #068b35!important;

}

@media(max-width:1200px){
  .customer-list-info-ctn .justify-space-between p{
    font-size: 0.7rem;
  }
  
}