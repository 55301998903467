.shift-report-print-header{
    border-bottom: 1px solid #000;
    font-size: 32px;
}
.shift-report-print-header img{
    width: 30%;
    max-width: 160px;
}
.shift-report-print-header p.head{
    margin: 0;
    font-weight: 600;
    font-size: 1em;
}
.shift-report-print-header p.address{
    
    text-align: center;
    margin: 0;
    line-height: 1.2;
    font-size: 0.8em;
}
.shift-report-print-header .shift-details{
    margin: 10px;
}
.shift-report-print-header .shift-details p{
    margin: 0;
    font-weight: 600;
    font-size: 32px;
}



.shift-report-print-container{
    max-height: fit-content;
    overflow-y: initial;
    font-family: Arial, sans-serif!important; /* Use simple, sans-serif fonts */
    font-size: 12px!important;
}
.shift-report-print-container .shift-report-print-container{
    background-color: transparent;
}
.shift-report-print-container .bill-main-head{
    background-color: transparent;
    font-size: 32px;
}
.shift-report-print-container .bill-main-head h5{
    font-size: 1em!important;
}
.shift-report-print-container .bill-sub-head h5{
    font-size: 0.9em!important;
}
.shift-report-print-container .bill-sub2-head h5{
    font-size: 0.8em!important;
}
.shift-report-print-container .bill-sub-head{
    background-color: transparent;
    border: 2px solid #000;
    margin-top: 6px;
    font-size: 32px;
}
.shift-report-print-container .bill-sub2-head{
    background-color: transparent;
    border-bottom: 2px dashed;
    font-size: 32px;
}
.shift-report-print-container table thead{
    background-color: transparent;
    border-bottom: 2px dashed;
}
.shift-report-print-container table thead th{
    font-weight: bolder;
    font-size: 24px !important;
}
.shift-report-print-container table tbody tr td{
    height: 29px;
    border-bottom: 1px solid #000;
    font-weight: 600;
    font-size: 24px !important;
}
.shift-report-print-container table tbody tr td.product-column div{
    word-break: break-word;
    margin: 0 auto;
}