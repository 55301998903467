.token-display-advertisement-container {
  width: 100%;
  padding: 0 1.5em;
  overflow-y: auto;
  overflow-x: hidden;
}
.token-advertisement-wrapper {
  margin-top: 2em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 25em;
}
.upload-add-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.upload-add-header span {
  font-size: 13px;
  font-weight: 800;
}
.upload-advertisement-body form {
  margin-top: 2em;
  display: flex;
  flex-direction: column;
  gap: 1em;
}
.token-display-advertisement-action-button {
  display: flex;
  gap: 1em;
  justify-content: center;
  align-items: center;
}
.token-display-advertisement-action-button button {
  width: 5em;
  height: 2.5em;
  cursor: pointer;
  border: 1px solid black;
  padding: 0.5em;
  /* background-color: #160894;
  color: white; */
}
.token-display-advertisement-action-button button[type="clear-advertisement"] {
  background-color: #ffff;
}
.token-display-advertisement-action-button button[type="update-advertisement"] {
  background-color: #160894;
  color: #ffff;
}

.ticket-advertisement-image-upload-container {
  padding: 0.5em;
  border: 1px solid #969494;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center; /* Center items vertically */
}
.image-name-container {
  width: calc(100% - 6em);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.upload-add-image-button-container {
  width: 6em;
  cursor: pointer;
  padding: 0.5em;
  background-color: #160894;
  border-radius: 5px;
}
.custom-file-upload {
  display: flex;
  align-items: center;
  color: white;
  cursor: pointer;
}

.ticket-advertisement-image-upload-container:hover {
  border: 1px solid #000000;
}
.horizontal-line-dotted {
  border: 1px dotted black;
}

/* new design  */
.adv-selection-temp {
  display: flex;
}
.adv-selection-temp > div {
  display: inherit;
  height: 7em;
  /* width: 5.2em; */
}
.adv-duration-cntr {
  display: flex;
  align-items: center;
  width: 7.5em;
  justify-content: space-between;
}
.advr-in-de-btn-cntr {
  display: flex;
  flex-direction: column;
}
.advr-in-de-btn-cntr button{
  padding: 0;
  height: 0.5em;
}
.adv-screen-selection-header {
  margin: 1em 0;
  font-weight: 600;
}
.adv-duration-cntr input {
  height: 3em;
  width: 2em;
  border: transparent;
  color: #160894;
}
.adv-branch-selector-cntr {
  margin: 1em 0;
}
.advertisement-card{
  margin-top: 0.5em;
  /* display: flex; */
  /* background-color: red; */
  /* height: 71vh; */
    /* width: 96.7vw; */
}

.advertisement-card .video-container {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 aspect ratio for a video */
  height: 0;
}
.advertisement-card .video-container iframe,
.advertisement-card .video-container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.adv-file-upload-cntr {
  margin-top: 1em;
  display: flex;
  align-items: center;
  gap: 0.5em;
}
.adv-file-upload-cntr > div:nth-child(1) {
  flex: 1;
}
/* .advr-in-de-btn-cntr .css-78trlr-MuiButtonBase-root-MuiIconButton-root {
  padding: 0;
  height: 0.5em;
} */
.adv-image-box {
  border: 1px solid black;
  padding: 0.5em;
  place-content: center;
  align-self: center;
  display: flex;
}

/* template */
.sq-container {
  display: flex;
  justify-content: center;
  gap: 1em;
}
.outer-square {
  width: 80px;
  height: 80px;
  background-color: var(--border-color, #f0f0f0);
  position: relative;
  align-self: center;
  border: 3px solid var(--border-color, #f0f0f0);
  transition: all 0.3s ease-in-out;
}

.inner-square {
  width: 48%;
  height: 48%;
  background-color: #fbfbfb;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}

.top-left {
  top: 0;
  left: 0;
}

.top-right {
  top: 0;
  right: 0;
}

.bottom-left {
  bottom: 0;
  left: 0;
}

.bottom-right {
  bottom: 0;
  right: 0;
}

.center {
  top: calc(105% / 3);
  left: 0;
}

.adv-upload-img-preview-cntr {
  margin-top: 1em;
}
.adv-upload-img-preview-cntr .ticket-advertisement-image-upload-container {
  margin: 1em 0;
}
.adv-prev-img {
  margin-right: 15px;
  min-width: 50px;
  width: 50px;
  height: 50px;
  overflow: hidden;
}
.adv-prev-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.adv-dlt-cntr {
  /* padding: 0.5em; */
  height: 2.5em;
  background-color: #fdedec;
  color: red;
  cursor: pointer;
}

.token-expand-btn {
  position: relative;
}

.token-expand-btn > div {
  position: absolute;
  top: 0%;
  left: 1%;
  transform: translate(-75%, -100%);
  z-index: 9;
}
.token-expand-btn > div > button {
  border: transparent;
  height: 1em;
  width: 3em;
  padding: 1.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.update-adv.token-expand-btn > div{
  left:10%;
}
.close-adv.token-expand-btn > div{
  left: 100%;
}
