.header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 0.5em;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
  background-color: #f6f6f6;
}
.header-wrapper .header-container ul {
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 0.5em;
  margin: 0;
}
.header-wrapper .header-container ul li {
  max-width: 6em;
  width: 6em;
  overflow: hidden;
}
.header-wrapper .header-container ul li .tool-box-btn {
  display: flex;
  align-items: center;
  padding: 0.5em 0;
  height: 2.5em;
  cursor: pointer;
  background-color: transparent;
  font-size: 12px;
  font-weight: 600;
  color: black;
  border: transparent;
  justify-content: space-around;
  width: 100%;
}
.header-wrapper .tool-name {
  font-size: 12px;
  font-weight: 600;
}
.toggle-switch-wrapper {
  display: flex;
  align-items: center;
  gap: 0.5em;
}

.header-container {
  position: relative;
}

.branch-section .content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 10;
  top: 10;
  display: block;
}
.branch-section .content .detail-container::before {
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid white;
  top: -10px;
  content: " ";
  height: 0;
  left: 25%;
  margin-left: -24px;
  position: absolute;
  width: 0;
}
.branch-section .content .detail-container {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 1em;
  background-color: white;
  max-width: 15em;
  width: 15em;
}

.branch-section:hover .content {
  display: block;
}
.tool-box-btn.add-btn-cnt {
  cursor: default !important;
}

.table-tool-box {
  width: 100%;
  display: flex;
  align-items: center;
  border: transparent;
  cursor: pointer;
  background-color: transparent;
  padding: 0.5em;
  gap: 1em;
}
.table-tool-box:hover{
  background-color: #f9f9f9;
  
}

.detail-container ul li {
  width: 100% !important;
  max-width: 100% !important;
}
.table-tool-box .icon-image-container {
  height: 25px;
  width: 25px;
}
.table-tool-box .icon-image-container:hover{
  color:#0022ff ;
}