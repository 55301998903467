.billing-calculator {
    width: 96%;
    padding: 4px;
    margin: 2%;
    border-radius: 2px;
    background-color: #fff;
    align-self: center;
  }
  
  .billing-display {
    margin-bottom: 0px;
  }
  .billing-display input{
    height: 35px;
    text-align: end;
    width: 100%;
    border: 2px solid #cacaca;
    background-color: transparent;
    font-size: 1rem;
  }
  
  .billing-input-section {
    display: flex;
    flex-direction: column;
    /* flex-wrap: wrap; */
    justify-content: space-between;
  }
  
  .billing-input-section button {
    width: 23%;
    margin: 5px;
    padding: 10px;
    font-size: 16px;
    text-align: center;
    background-color: #37474f;
    color: #fff;
    border: none;
  }
  .billing-input-section button.white-btn {

    padding: 10px;
    /* background-color: #fff; */
    background-color:#e7e7e7;
    color: #37474f;
    font-weight: 600;
  }
  .billing-input-section button.black-btn {

    padding: 10px;
    background-color: #000;
    color: #fff;
  }
  
  .billing-input-section button.operator,
  .billing-clear {
    background-color: #ff9a8b;
  }
  
  .billing-input-section button.number,
  .billing-percent,
  .billing-root {
    background-color: #f0f0f0;
  }
  
  .billing-input-section button.equal {
    width: 48%; /* The equal button takes up almost half the space */
    background-color: #e85a4f;
    color: #fff;
  }
  
  .billing-input-section button:hover {
    background-color: #ddd;
    cursor: pointer;
  }
  
  .billing-input-section .row{
    display: flex;
  }

  /* keyboard */
  .keyboard-container {
    width: 50vw;
    min-width: 750px;
  }
  .hg-button-enter{
    background-color: rgb(19, 145, 9)!important;
    color:#fff !important;
  }

  .keyboard-icon-billing{
    position: absolute !important;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    color: #44ac3b !important;
  }

  .billing-keyboard-position .hg-theme-default{
    background-color: #8b8b8b;
  }
  .billing-keyboard-position .hg-theme-default .hg-button{
    background-color: #454545;
    color: #fff;
  }
  