.shift-report-table-container{
    max-height: 73vh;
    overflow-y: auto;
    position: relative;
}

.shift-report-table-container::-webkit-scrollbar{
    width: 8px;
    background-color: #91a2ac;
}
.shift-report-table-container::-webkit-scrollbar-thumb{
    background-color: #a6b9c4;
}

.report-totalbar{
    display: flex;
    background-color: #b7b5b5;
    margin-top: 10px;
    position: absolute;
    width: 100%;
    padding: 0 1%;
    top: 96%;
    z-index: 20;
    
}
.report-totalbar tfoot{
    background-color: #b7b5b5;
    height: 60px;
}
.report-totalbar table{
    background-color: #b7b5b5;
}
.report-totalbar thead{
    height: 0!important;
    opacity: 0;
    top: 25px !important;
}
.report-totalbar tfoot td{
    text-align: center;
}


.aggregator-head-td{
    height: 50px!important;
    font-size: 1rem!important;
    font-weight: 600;
    background-color: #bcbcbc;
    text-align: start!important;
    padding: 0 20px;
}

.aggregaor-order-list-tr span{
    padding: 6px 10px;
    color: #fff;
    width: 80px;
}

.aggregator-footer-tr{
    background-color: #bcbcbc;
}
.aggregator-footer-tr td{
    font-size: 0.8rem!important;
    font-weight: 600;
    cursor: default !important;;
}
.aggregator-footer-tr td.pay-btn{
    background-color: #61a85c;
    cursor: pointer!important;
}

.aggregator-report-payment-table tbody tr{
    border: 1px solid #000;
}
.aggregator-report-payment-table tbody td{
    border-bottom: 1px solid #000 !important;
}
.aggregator-report-payment-table tbody td div.product-table-auto-complete{
    padding: 5px 0;
}
.aggregator-report-payment-table tbody td div.MuiAutocomplete-root{
    border: 1px solid #000 !important;
}
.aggregator-report-payment-table tbody td input.border-bottom-input{
    border: none;
    border-bottom: 1px solid #000;
    width: 90px;
}
.aggregator-report-payment-table tbody td div.MuiAutocomplete-root fieldset{
    top:0 !important;
}
.aggregator-report-payment-table tbody td div.MuiAutocomplete-root .MuiAutocomplete-popupIndicator {
    display: inline-flex !important;
}
.aggregator-report-payment-table tbody td div.MuiInputBase-root{
    padding: 0 9px!important;
    padding-right: 39px!important;
}

.flex-end{
    display: flex;
    justify-content: flex-end;
}
.aggregator-reeport-payment-bottom{
    gap: 10px;
    padding: 0 1%;
}
.aggregator-reeport-payment-bottom .global-single-input{
    flex-direction: row;
    align-items: center;
    gap: 8px;
}
.aggregator-reeport-payment-bottom .global-single-input input{
    background-color: transparent;
    border: 1px solid #000;
}

.proceed-btn{
    background-color: #7696ee;
    font-size: 0.9rem;
    height: 30px;
    margin-left: auto;
    margin-bottom: 15px;
}