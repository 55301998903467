.kot-element-body {
  width: 100%;
  overflow-y: auto;
  padding: 1.5em;
  height: 89vh;
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
  justify-content: center;
  /* ------------------------------------- */
  /* grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(8, auto); */
  /* 
  grid-template-columns: repeat(3, auto);
  grid-template-rows: repeat(3, auto); */
}
