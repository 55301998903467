.category-list-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100% - 20px);
  overflow-y: auto;
  direction: rtl;
  width: fit-content;
  max-width: 90px;
  min-width: 90px;
  margin: 10px 0;
  box-shadow: 5px 0 10px rgba(0, 0, 0, 0.3);
  margin-top: 0;
  padding-top: 10px;
}

.category-list-container::-webkit-scrollbar, .self-product-card-ctn::-webkit-scrollbar {
  width: 3px;
}

.category-list-container::-webkit-scrollbar-track,.self-product-card-ctn::-webkit-scrollbar-track {
  background-color: #ffffff;
}

.category-list-container::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 6px;

}
.category-list-container .self-selected-selector{
   background-color: #d7d7d7;
    padding: 10px;
    border-radius: 12px;
}
.category-list-container .item {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
  margin-bottom: 10px;
  text-align: center;
}

.category-list-container .item img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.category-list-container .item p {
  margin-top: 5px;
  font-size: 12px;
  font-weight: bold;
}

.category-list-container .item.in-view {
  opacity: 1;
  transform: translateY(0);
}

.self-product-card-ctn{
  display: flex;
    flex-wrap: wrap;
    justify-content: center;
    height: fit-content;
    max-height: calc(100% - 20px);
    overflow-y: auto;
    margin:4px ;
    gap: 10px;
}
.self-product-card-item{
  width: 100px;
  padding: 10px;
  margin: 6px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow:1px 1px 0px 2px rgba(128, 128, 128, 0.5);
  text-align: center;
  transition: transform 0.3s ease-in-out;
  max-height: fit-content;
  position: relative;
}
.self-product-card-item:hover {
  transform: translateY(-10px);
}
.self-product-card-ctn img.product-card-image{
  width: 100%;
  height: 60px;
  object-fit: cover;
  margin-bottom: 5px;
}
.self-product-card-ctn h5{
  font-size: 14px;
  margin: 0;
  font-weight: bold;
  word-break: break-word;
  text-align: start;
}
.self-product-card-ctn p{
  font-size: 12px;
  text-align: start;
  margin: 2px 0;
  color: #555;
}


.self-sub-category-parent{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-inline: 10px;
  box-shadow: 1px 2px 3px #9d9a9a;
}
.self-sub-category-parent .self-order-login-btn {
  width: 20%;
  margin: 6px;
}
.self-sub-category-parent .selected-category {
  display: flex;
  padding: 4px;
  border: 0.5px solid #e11503;
  font-size: 12px;
  min-width: 30px;
  justify-content: center;
  cursor: pointer;
}
.sub-lists-menu-category{
  font-size: 12px;
  padding: 4px;
  border: 1px dashed #055516;
  margin: 2px 4px;
  min-width: 60px;
  text-align: center;
  cursor: pointer;

}
.sublist-menu-selected-category{
  background-color: #e11503;
  color: #FFF;
  border: none;
  margin: 6px 4px;
}

.self-order-special-item-card{
  padding: 7px;
  border: 1px solid #e7e7e7;
  border-radius: 6px;
  box-shadow: 3px 4px 0px #cec0c0;
  cursor: pointer;
  min-width: 155px;
  text-align: center;
}
.self-order-special-item-card img{
  width: 120px;
  height: 120px;
}
.self-order-special-item-card h4{
  margin: 4px 0;
}
.self-order-special-item-ctn .self-order-button{
  background-color: #e11503;
  color: #fff;
  border: none;
  max-width: 200px;
}
.special-items-card-list{
  height: fit-content;
  flex-wrap: wrap;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.self-order-special-item-ctn h3{
  text-align: center;
  font-size: 1.6rem;
}








/* MEDIA QUERIES */
@media (min-width: 768px) {
  .category-list-container{
    max-width: 120px;
  }
  .category-list-container .item{
    margin-bottom: 20px;
  }
.category-list-container .item img {
  width: 80px;  
  height: 80px;  
  }
.category-list-container .item p {
    font-size: 14px;
}
.self-sub-category-parent .selected-category{
  padding: 8px;
  min-width: 60px;
  font-size: 16px;
}
.self-sub-category-parent{
  padding: 10px 30px;
}
.sub-lists-menu-category{
  font-size: 14px;
  min-width: 90px;
  margin: 5px 7px;
  padding: 6px 8px;
}
.self-order-special-item-ctn h3{
font-size: 2rem;
}

}
@media (min-width: 1024px) {
  .category-list-container{
    max-width: 140px;
  }
  .category-list-container .item{
    margin-bottom: 30px;
  }
  .category-list-container .item img {
    width: 100px;  
    height: 100px;  
    }
  .category-list-container .item p {
      font-size: 16px;
  }

  .self-product-card-item{
    width: 120px;
    padding: 20px;
  }
.self-product-card-ctn img.product-card-image{
  height: 80px;
  margin-bottom: 10px;
}
.self-product-card-ctn h5{
  font-size: 16px;
}
.self-product-card-ctn p{
  font-size: 14px;
}
}
@media (max-height: 800px) and (min-width: 768px) {

}