.kot-header-container {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: var(--background);
  padding: 10px 0;
  width: 100%;
  z-index: 1000;
  /* height: 11vh; */
  padding: 6px 1em;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  transition: var(--transition);
}

.kot-filter {
  padding: 5px;
  border: 1px solid var(--border);
  width: 35px;
  height: 35px;
  background-color: transparent;
  cursor: pointer;
}

.kot-header-container .kot-header {
  /* background-color: #e64f4f; */
  padding: 0.5em;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.kot-action-button {
  display: flex;
  gap: 0.5em;
}
.kot-action-button > div {
  transition: all 0.3s ease-in-out;
  height: 50px;
}
.kot-header :nth-child(1) {
  /* align-self: baseline; */
}
.kot-header :last-child {
  /* align-self: baseline; */
}

.network-status {
  color: var(--text-color);
  font-weight: 600;
  padding-right: 0.5em;
}

.kot-action-container {
  display: flex;
  align-items: center;
}
.close-icon-btn{
  border: transparent;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5em;
}
.kot-header-left-side{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1em;
}
