:root {
  --background: white;
  --border:black;
  --text-color:black;
  --print-container: #cfd3dc;
  --focus:#5e0101;
  --transition: all 0.3s ease-in-out;
  --list-hover:aliceblue
}
[data-theme="dark"] {
  --background: black;
  --border:white;
  --text-color:white;
  --print-container: #414141;
  --focus:white;
  --list-hover:#414141
}

#kot-container {
  background-color: #465378;
  max-height: 100vh;
  overflow: hidden;
}
#kot-container .kot-container {
}
.kot-body {
  display: flex;
}

