.Header p{
    margin: 0;
    font-weight: 600;
}
.food_config_secound_header{
    display: flex;
    justify-content: space-between;
    padding-inline: 20px;
}
.food_config_div{
    display: flex;
    padding: 40px 10px 40px 30px;
}

/* recipe */

.div_data_container{
    display: flex;
    justify-content: space-between;
    padding-inline: 10px;
}
.button-div{
    padding: 46px 0px 0px 0px;
}
.button-div button{
    padding: 7px;
    border: none;
    padding-inline: 20px
}
.shiftButton-div button.tab-btn {
    padding: 8px;
    border: 1px solid white;
    padding-inline: 20px;
    cursor: pointer;
}

.shiftButton-div button.selected-button {
    background-color: #d6d4d4;
    border-bottom: 1px solid black;
    color: black;
    padding: 0 0 0 8px;

}

.shiftButton-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.shiftButton-div p {
    margin: 0;
    font-size: 14px;
}

.shiftButton-div input {
    cursor: pointer;
}



.selected-button {
    background-color: rgb(139, 138, 138);
    border-bottom: 3px solid black!important;
  
  }
  .receipe-card{
    border-left:10px solid #9c9cdd; 
    width: 23%;
    display: flex;
    flex-direction: column;
    padding: 10px;
    padding-left: 15px;
    border-radius: 3px;
    box-shadow: 1px 2px 5px 1px #e8e8e8;
    background-color: #edf2f9;
    margin: 1%;
  }

  .bill-main-head{
    display: flex;
    justify-content: space-between;
    width: 100%;
    background-color: #edf2f9;
    align-items: center;
    padding:10px 2%;
  }
  .bill-main-head h5{
    margin: 0;
    font-size: 1rem;
  }
  .bill-sub-head{
    display: flex;
    justify-content: space-between;
    width: 100%;
    background-color: #E6E6E6;
    align-items: center;
    padding: 6px 2%;
  }
  .bill-sub-head h5,.bill-sub2-head h5{
    margin: 0;
  }
  .bill-sub2-head{
    display: flex;
    justify-content: space-between;
    width: 100%;
    background-color: #c2c2c2;
    align-items: center;
    padding: 6px 2%;

  }

  .advance-shift-report-table-container table thead{
    height: auto!important;
    padding:  4px 0;
  }
  .advance-shift-report-table-container table thead  th{

    padding:  5px 0;
  }